import React from 'react';

import { initializeApp } from "firebase/app"; // new as of saturday
import {
  getAuth,
  AuthErrorCodes,
  checkActionCode,
  applyActionCode,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";

import { RingLoader, ClipLoader } from 'react-spinners';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

import Colors from "../Colors";
//import Fonts from "../Fonts";
import '../styles/Validate.css';
//import logo from '../images/logo-256.png';
import logo from '../images/wordAndLogo.svg';


/*

https://dev.auth.groupspot.app?mode=verifyAndChangeEmail&oobCode=mYB3tIBI2B0MfmeNwPwH5DOniDSvURZSLuuQWuT01jMAAAGNe_7Cww&apiKey=AIzaSyCy6kvILVRuin_j2oj-fc-sMKXclPyrOSA&lang=en

 */

class Validate extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      message: '',
      isFetching: true,
      isLoading: false,
      mode: '',
      actionCode: '',
      password: '',
      password2: '',
      errors: {},
      showError: false,
      updatedPassword: false,
    };


    const queryParams = new URLSearchParams(window.location.search)

    this.mode = queryParams.get("mode");
    this.actionCode = queryParams.get("oobCode");
    this.apiKey = queryParams.get("apiKey");


    if (this.apiKey !== undefined && this.apiKey != null) {

      const app = initializeApp({
        apiKey: this.apiKey,
      });

      this.authRef = getAuth(app);
    }
  };



  componentDidMount() {
    this.checkPayload().then();
  }


  checkPayload = async() => {

    if (this.mode === 'verifyEmail' && this.authRef !== undefined) {

      this.handleVerifyEmail();

    } else if (this.mode === 'resetPassword' && this.authRef !== undefined) {

      this.handleResetPassword();

    } else if (this.mode === 'recoverEmail' && this.authRef !== undefined) {

      this.handleRecoverEmail();

    } else if (this.mode === 'verifyAndChangeEmail' && this.authRef !== undefined) {

      this.handleChangeEmail();

    } else {

      this.setState({
        isFetching: false,
      });
    }

  };


  handleRecoverEmail = () => {

    let _thisRef = this;
    let restoredEmail = null;

    checkActionCode(this.authRef, this.actionCode).then(function(info) {

      restoredEmail = info['data']['email'];
      // revert to the old email.
      //return _thisRef.authRef.applyActionCode(_thisRef.actionCode);
      return applyActionCode(_thisRef.authRef, _thisRef.actionCode);

    }).then(function() {

      // email reverted to restoredEmail
      _thisRef.setState({
        isFetching: false,
        message: 'Your acct has been restored to ' + restoredEmail,
      });

      // You might also want to give the user the option to reset their password
      // in case the account was compromised:

      /*
      _thisRef.authRef.sendPasswordResetEmail(restoredEmail).then(function() {
        // Password reset confirmation sent. Ask user to check their email.
      }).catch(function(error3) {
        // no need to do anything here
      });
      */

    }).catch(function(e) {

      //console.log(e.code, e.message);

      let localMessage = 'Something went wrong.';

      if (e.code === AuthErrorCodes.INVALID_OOB_CODE) {
        localMessage = 'Code is invalid or has already been used.'
      }

      _thisRef.setState({
        isFetching: false,
        message: localMessage,
      });
    });
  };




  actuallyResetPassword = () => {

    this.setState({
      isLoading: true,
    });

    let _thisRef = this;

    confirmPasswordReset(this.authRef, this.actionCode, this.state.password).then(function(_) {
      // Password reset has been confirmed and new password updated.

      _thisRef.setState({
        isFetching: false,
        isLoading: false,
        password: '',
        password2: '',
        message: 'Successfully updated password.',
        updatedPassword: true,
      });


    }).catch(function(e) {
      // Error occurred during confirmation. The code might have expired or the
      // password is too weak.

      //console.log(e.code, e.message);

      let localMessage = 'Something went wrong.';

      if (e.code === AuthErrorCodes.INVALID_OOB_CODE) {
        localMessage = 'Code is invalid or has already been used.'
      }

      _thisRef.setState({
        isFetching: false,
        message: localMessage,
        showError: true,
      });
    });

  };



  handleResetPassword = () => {

    let _thisRef = this;

    // Verify the password reset code is valid.
    verifyPasswordResetCode(this.authRef, this.actionCode).then(function(email) {

      _thisRef.setState({
        isFetching: false,
        message: email,
      });

    }).catch(function(e) {

      //console.log(e.code, e.message);

      let localMessage = 'Something went wrong.';

      if (e.code === AuthErrorCodes.INVALID_OOB_CODE) {
        localMessage = 'Code is invalid or has already been used.'
      }

      _thisRef.setState({
        isFetching: false,
        message: localMessage,
        showError: true,
        updatedPassword: true,
      });
    });

  };


  handleVerifyEmail = () => {

    let _thisRef = this;

    applyActionCode(this.authRef, this.actionCode).then(function(_) {

      _thisRef.setState({
        isFetching: false,
        message: 'Verified.',
      });

    }).catch(function(e) {

      //console.log(e.code, e.message);

      let localMessage = 'Oops. Something went wrong.';

      if (e.code === AuthErrorCodes.EXPIRED_OOB_CODE) {
        localMessage = 'This code has since expired.  Please generate a new request.'
      } else if (e.code === AuthErrorCodes.INVALID_OOB_CODE) {
        localMessage = 'Code is either invalid or has already been used. Please confirm you\'re using the proper one.'
      }

      _thisRef.setState({
        isFetching: false,
        message: localMessage,
      });
    });

  };



  handleChangeEmail = () => {

    let _thisRef = this;

    applyActionCode(this.authRef, this.actionCode).then(function(_) {

      _thisRef.setState({
        isFetching: false,
        message: 'Email updated.',
      });

    }).catch(function(e) {

      console.log(e.code, e.message);

      let localMessage = 'Oops. Something went wrong.';

      if (e.code === AuthErrorCodes.EXPIRED_OOB_CODE) {
        localMessage = 'This code has since expired.  Please generate a new request.'
      } else if (e.code === AuthErrorCodes.INVALID_OOB_CODE) {
        localMessage = 'Code is either invalid or has already been used. Please confirm you\'re using the proper one.'
      }

      _thisRef.setState({
        isFetching: false,
        message: localMessage,
      });
    });

  };




  handleActionClick = () => {
    this.setState({ showError: false });
  };

  /*
    handleRequestClose = () => {
      this.setState({ showError: false });
    };
  */


  render() {

    if (this.state.isFetching) {
      return (
        <div className="masterValidate">
          <RingLoader
            color={'#031d44'}
            loading={true}
          />
        </div>
      );
    }


    if (this.mode === 'resetPassword') {
      return (
        <div className="masterResetPassword">

          <header className="validate-header">
            <img src={logo} className="validate-logo" alt="logo" />
          </header>

          <div className="containerEmail">
            <div className="welcomeText">{this.state.message}</div>
          </div>


          {!this.state.showError && this.state.updatedPassword === false &&
            <div className="containerForm">

              <div className="containerTextfield">
                <TextField
                  color="secondary"
                  focused
                  fullWidth={true}
                  label={'New Password'}
                  variant={'outlined'}
                  onChange={(e) => this.setState({password: e.target.value})}
                  value={this.state.password}
                  type={"password"}
                />
              </div>

              <div className="containerTextfield">
                <TextField
                  color="secondary"
                  focused
                  fullWidth={true}
                  label={'Repeat New Password'}
                  variant={'outlined'}
                  onChange={(e) => this.setState({password2: e.target.value})}
                  value={this.state.password2}
                  type={"password"}
                />

              </div>

              <div className="containerButton">

                {!this.state.isLoading &&
                  <Button
                    variant={"contained"}
                    color={"success"}
                    size={'large'}
                    onClick={this.actuallyResetPassword}
                    disabled={
                      (this.state.password.length<6 || this.state.password2.length<6) ||
                      (this.state.password !== this.state.password2)
                    }
                  >
                    RESET PASSWORD
                  </Button>
                }

                {this.state.isLoading &&
                  <ClipLoader
                    color={'#031d44'}
                    loading={true}
                  />
                }

              </div>


            </div>
          }


          <Snackbar
            open={this.state.showError}
            message={'Oops. Something went wrong.'}
            autoHideDuration={5000}
            onClose={this.handleActionClick}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}
          >
            <SnackbarContent style={{
              backgroundColor: Colors.error,
            }} message={<span id="client-snackbar">Oops. Something went wrong.</span>}
            />

          </Snackbar>

        </div>
      )
    }


    if (this.mode === 'verifyEmail') {
      return (
        <div className="masterValidate">

          <header className="validate-header">
            <img src={logo} className="validate-logo" alt="logo" />
          </header>

          <div className="containerMessage">
            <div className="welcomeText">{this.state.message}</div>
          </div>

        </div>
      )
    }


    if (this.mode === 'verifyAndChangeEmail') {
      return (
        <div className="masterValidate">

          <header className="validate-header">
            <img src={logo} className="validate-logo" alt="logo" />
          </header>

          <div className="containerMessage">
            <div className="welcomeText">{this.state.message}</div>
          </div>

        </div>
      )
    }


    if (this.mode === 'recoverEmail') {
      return (
        <div className="masterValidate">

          <header className="validate-header">
            <img src={logo} className="validate-logo" alt="logo" />
          </header>

          <div className="containerMessage">
            <div className="welcomeText">{this.state.message}</div>
          </div>

        </div>
      )
    }


    return (
      <div className="masterValidate">

        <header className="validate-header">
          <img src={logo} className="validate-logo" alt="logo" />
        </header>

      </div>
    )


  }

}


/*
const styles = {

  errorStyle: {
    color: Colors.error,
  },
  underlineStyle: {
    borderColor: Colors.formOutline,
  },

  underlineFocusStyle: {
    borderColor: Colors.formOutline,
  },

  floatingLabelStyle: {
    color: Colors.reallylightGrey,
  },
  floatingLabelFocusStyle: {
    color: Colors.darkText,
  },

  inputStyle: {
    color: Colors.formText,
    //color: '#ffffff',
  },

  button: {
    fontFamily: Fonts.button,
  },

};
*/

export default Validate;

