import React from 'react';

import {Routes, Route} from 'react-router-dom';

//import withAuth from './RouteProtector.js'

import Home from './Home';
import Validate from './Validate';

//  <Route path="/:hash" component={Validate}/>

class App extends React.Component {

  render() {

    return (
      <Routes>
        <Route path="/" element={<Validate/>} />
        <Route element={<Home/>}/>
      </Routes>
    );
  }
}


export default App;