//import React from 'react';
//import ReactDOM from 'react-dom';

import React from "react";
import ReactDOM from "react-dom/client";


import { BrowserRouter as Router } from 'react-router-dom';
//import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
//import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

//import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {createTheme, ThemeProvider} from '@mui/material';

import App from './components/App';
import * as serviceWorker from './serviceWorker';

//import registerServiceWorker from './registerServiceWorker';

if (process.env.REACT_APP_ENV === 'dev') {
  require('./styles/Index-dev.css');
} else {
  require('./styles/Index-prod.css');
}

if (process.env.REACT_APP_ENV === 'dev') {
  require('./styles/Index-dev.css');
} else {
  require('./styles/Index-prod.css');
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff'
    },
    secondary: {
      main: '#031d44'
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Router>
  </React.StrictMode>
);

/*
ReactDOM.render(
  <Router>
    <MuiThemeProvider>
      <App />
    </MuiThemeProvider>
  </Router>,

  document.getElementById('root')
);
*/


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
//registerServiceWorker();