const purple = '#6b327f';
const red = '#F65857';
//const blue = '#1f8fe0';
const blue = '#3a99fe';
const green = '#4BB543'; // this is better for web
//const green = '#1b5e20';
const orange = '#FF6B35';


const colorObj = {

  error: red,
  colorError: red,
  success: green,
  alert: red,
  warn: orange,

  purple: purple,
  blue: blue,
  green: green,

  yellow: '#ffff00',

  darkGrey: '#424242',
  lightGrey: '#757575',
  reallylightGrey: '#e0e0e0',

  lightText: '#ffffff',
  darkText: '#000000',

  headerBg: '#ffffff',
  footerBg: '#dddddd',

  headerText: purple,

  formText: purple,
  formOutline: blue,

};

export default colorObj;