import React from 'react';

import logo from '../images/logo-256.png';
import '../styles/Home.css';


class Home extends React.Component {

  render() {

    return (

      <div className="homeMaster">

        <header className="home-header">
          <img src={logo} className="home-logo" alt="logo" />
        </header>

      </div>
    );

  }

}


export default Home;

